<template>
  <div>
    <b-card-code no-body v-if="acesso.CadastroOrgaoConsulta">
      <b-card-body>
        <b-row>
          <b-col md="2">
            <b-form-group label="Pesquisar Por" label-for="opcao">
              <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                placeholder="Descrição"
                v-model="pesquisar.descricao"
                v-on:keydown.enter="carregarGrid"
              />
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="pesquisarOrgao()"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
          <b-col md="auto" align="right" v-show="this.acesso.CadastroOrgaoCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="novoRegistro()"
            >
              Novo Cadastro
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="auto" align="right" v-show="!this.acesso.CadastroOrgaoConsulta && this.acesso.CadastroOrgaoCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="novoRegistro()"
            >
              Novo Cadastro
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>

      <b-table
        v-show="this.totalRows > 0"
        striped
        responsive
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy="isBusy"
        :current-page="pesquisar.currentPage"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template #cell(opcoes)="row">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            left
            :popper-opts="{ positionFixed: true }"
            :disabled="!acesso.CadastroOrgaoEditar && !acesso.CadastroOrgaoExcluir"
          >
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>
            <b-dropdown-item @click="editar(row.item.id)" v-show="acesso.CadastroOrgaoEditar">
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item @click="authEExclui(row.item.id)" v-show="acesso.CadastroOrgaoExcluir">
              <feather-icon icon="XIcon" size="16" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(instituto)="row">
          {{ row.item.instituto.nome }}
        </template>
      </b-table>

      <b-card-body v-if="this.totalRows > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Por Página"
          style="align-items: center"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="pesquisar.currentPage"
          :total-rows="totalRows"
          :per-page="pesquisar.perPage"
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>

      <!-- Modal Cadastro -->
      <b-modal id="modal-cadastro" ref="modal-cadastro" centered size="lg" title="Cadastro" no-close-on-backdrop>
        <validation-observer ref="formOrgao">
          <b-form>
            <b-tabs>
              <b-tab>
                <template #title>
                  <feather-icon icon="HomeIcon" />
                  <span>Dados Cadastrais</span>
                </template>
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Unidade Gestora" label-for="instituto">
                      <validation-provider #default="{ errors }" name="instituto" rules="required">
                        <v-select-pt
                          name="instituto"
                          v-model="dados.institutoId"
                          :options="instituto"
                          :reduce="(option) => option.id"
                          label="nome"
                        ></v-select-pt>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nome do Órgão" label-for="nome">
                      <validation-provider #default="{ errors }" name="Nome do Órgão" rules="required">
                        <b-form-input id="nome" type="text" placeholder="Nome do Órgão" v-model="dados.nome" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Abreviatura" label-for="abreviatura">
                      <b-form-input
                        id="abreviatura"
                        type="text"
                        placeholder="Abreviatura"
                        v-model="dados.abreviatura"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Código do Cliente" label-for="codigoCliente">
                      <validation-provider #default="{ errors }" name="Código do Cliente" rules="required">
                        <b-form-input
                          id="codigoCliente"
                          type="text"
                          placeholder="Código do Cliente"
                          v-model="dados.codigoCliente"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="CNPJ" label-for="CNPJ">
                      <validation-provider #default="{ errors }" name="CNPJ" rules="required">
                        <cleave
                          id="CNPJ"
                          type="text"
                          placeholder="CNPJ"
                          v-model="dados.cnpj"
                          :raw="false"
                          :options="options.cleaveCNPJ"
                          class="form-control"
                          @blur="validarInformacoes((campo = dados.cnpj), (id = 'CNPJ'))"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Poder" label-for="poder">
                      <validation-provider #default="{ errors }" name="Poder" rules="required">
                        <b-form-select name="poder" v-model="dados.poder" :options="poderOpcoes"></b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Esfera" label-for="esfera">
                      <validation-provider #default="{ errors }" name="Esfera" rules="required">
                        <b-form-select name="esfera" v-model="dados.esfera" :options="esferaOpcoes"></b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab>
                <template #title>
                  <feather-icon icon="UserIcon" />
                  <span>Responsável</span>
                </template>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nome do Responsável" label-for="responsavelNome">
                      <validation-provider #default="{ errors }" name="Nome" rules="required">
                        <b-form-input id="responsavelNome" type="text" placeholder="Nome" v-model="dados.responsavelNome" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Cargo do Responsável" label-for="responsavelCargo">
                      <validation-provider #default="{ errors }" name="Cargo" rules="required">
                        <b-form-input id="responsavelCargo" type="text" placeholder="Cargo" v-model="dados.responsavelCargo" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="E-mail" label-for="responsavelEmail">
                      <validation-provider #default="{ errors }" name="E-mail" rules="required">
                        <b-form-input
                          id="responsavelEmail"
                          @blur="isEmailValid"
                          type="text"
                          placeholder="E-mail"
                          v-model="dados.responsavelEmail"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Telefone" label-for="responsavelTelefone">
                      <validation-provider #default="{ errors }" name="Telefone" rules="required">
                        <cleave
                          id="responsavelTelefone"
                          @blur="isTelefoneValid"
                          type="text"
                          placeholder="Telefone"
                          v-model="dados.responsavelTelefone"
                          :raw="false"
                          :options="options.clevePhone"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon icon="MapPinIcon" />
                  <span>Endereço</span>
                </template>
                <b-row>
                  <b-col md="3">
                    <b-form-group label="CEP" label-for="responsavelCep">
                      <cleave
                        id="responsavelCep"
                        type="text"
                        placeholder="CEP"
                        v-model="dados.responsavelCep"
                        maxlength="10"
                        @keyup.native="buscarCEP"
                        :raw="false"
                        :options="options.cleaveCEP"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Logradouro" label-for="responsavelLogradouro">
                      <b-form-input
                        id="responsavelLogradouro"
                        type="text"
                        placeholder="Logradouro"
                        v-model="dados.responsavelLogradouro"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Número" label-for="responsavelNumero">
                      <b-form-input id="responsavelNumero" type="text" placeholder="Número" v-model="dados.responsavelNumero" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Bairro" label-for="responsavelBairro">
                      <b-form-input id="responsavelBairro" type="text" placeholder="Bairro" v-model="dados.responsavelBairro" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="UF" label-for="responsavelUf">
                      <b-form-select
                        name="responsavelUf"
                        v-model="dados.responsavelUf"
                        :options="ufOpcoes"
                        @change="carregarCidadeEndereco(dados.responsavelUf)"
                      >
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group label="Cidade" label-for="responsavelCidade">
                      <v-select-pt
                        name="responsavelCidade"
                        v-model="dados.responsavelCidade"
                        :options="responsavelCidade"
                        label="responsavelCidade"
                      >
                      </v-select-pt>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-form>
        </validation-observer>
        <template #modal-footer="{}">
          <b-button variant="outline-secondary" @click="$bvModal.hide('modal-cadastro')"> Fechar </b-button>
          <b-button variant="primary" @click="salvar" :disabled="loading">
            {{ !loading ? 'Salvar' : '' }}
            <b-spinner small v-if="loading" />
          </b-button>
        </template>
      </b-modal>

      <!-- INICIO DO VERIFICA SENHA -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card-code>
    <b-card class="text-center" v-else>
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para realizar pesquisas.</b-card-text>
        <b-card-text class="mb-1"
          >Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.</b-card-text
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { Ufs } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import { validaCNPJ } from '@/libs/utils'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      AutenticaSenha,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },
        carregando: false,
        required,
        number: {},
        instituto: [],
        tipoOpcoes: [
          { value: 1, text: 'Nome do Órgão' },
          { value: 2, text: 'CNPJ' },
          { value: 3, text: 'Nome do Responsável' },
        ],
        poderOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'Executivo', text: 'Executivo' },
          { value: 'Legislativo', text: 'Legislativo' },
          { value: 'Judiciario', text: 'Judiciário' },
          { value: 'MinisterioPublico', text: 'Ministério Público' },
          { value: 'Defensoria', text: 'Defensoria' },
        ],
        esferaOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'Federal', text: 'Federal' },
          { value: 'Estadual', text: 'Estadual' },
          { value: 'Municipal', text: 'Municipal' },
        ],
        responsavelCidade: [],
        ufOpcoes: Ufs,
        options: {
          cleaveCNPJ: {
            delimiters: ['.', '.', '/', '-'],
            numericOnly: true,
            blocks: [2, 3, 3, 4, 2],
          },
          clevePhone: { phone: true, phoneRegionCode: 'BR', delimiter: ' ' },
          cleaveCEP: {
            delimiters: ['.', '.', '-'],
            blocks: [2, 3, 3],
          },
        },
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'nome', label: 'Nome do Órgão', sortable: true },
          { key: 'cnpj', label: 'CNPJ', sortable: true },
          { key: 'instituto', label: 'Nome da Unidade Gestora', sortable: true },
          {
            key: 'responsavelNome',
            label: 'Nome do Responsável',
            sortable: true,
          },
        ],
        items: [],
        dados: {},
        acesso: {
          CadastroOrgaoConsulta: false,
          CadastroOrgaoCriarNovo: false,
          CadastroOrgaoEditar: false,
          CadastroOrgaoExcluir: false,
        },
        loading: false,
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.CadastroOrgaoConsulta = true
        this.acesso.CadastroOrgaoCriarNovo = true
        this.acesso.CadastroOrgaoEditar = true
        this.acesso.CadastroOrgaoExcluir = true
      } else {
        this.acesso.CadastroOrgaoConsulta = this.userData.GrupoAcesso.CadastroOrgaoConsulta
        this.acesso.CadastroOrgaoCriarNovo = this.userData.GrupoAcesso.CadastroOrgaoCriarNovo
        this.acesso.CadastroOrgaoEditar = this.userData.GrupoAcesso.CadastroOrgaoEditar
        this.acesso.CadastroOrgaoExcluir = this.userData.GrupoAcesso.CadastroOrgaoExcluir
      }
      this.carregarInstituto()
      // Conforme reunião retiramos a consulta automática
      // this.carregarGrid();
    },
    methods: {
      validarInformacoes(campo, id) {
        if (id == 'CNPJ') {
          if (validaCNPJ(campo) == false) {
            this.msgError('Erro: O CNPJ digitado não é válido.')
            this.dados.cnpj = ''
          }
        }
      },
      isEmailValid() {
        return this.dados.responsavelEmail == ''
          ? ''
          : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
              this.dados.responsavelEmail,
            )
          ? true
          : {
              ...this.msgError('Erro: O e-mail digitado não é válido.'),
              ...(this.loading = false),
            }
      },
      isTelefoneValid() {
        this.loading = false
        if (this.dados.responsavelTelefone == undefined) return this.msgError('Erro: O campo telefone digitado não é válido.')
        this.dados.responsavelTelefone = this.dados.responsavelTelefone.replace(/[^\d]+/g, '')
        if (
          this.dados.responsavelTelefone == '0000000000000' ||
          this.dados.responsavelTelefone == '000000000000' ||
          this.dados.responsavelTelefone == '00000000000' ||
          this.dados.responsavelTelefone == '0000000000' ||
          this.dados.responsavelTelefone == '99999999999' ||
          this.dados.responsavelTelefone == '9999999999' ||
          this.dados.responsavelTelefone == '88888888888' ||
          this.dados.responsavelTelefone == '8888888888' ||
          this.dados.responsavelTelefone == '77777777777' ||
          this.dados.responsavelTelefone == '7777777777' ||
          this.dados.responsavelTelefone == '66666666666' ||
          this.dados.responsavelTelefone == '6666666666' ||
          this.dados.responsavelTelefone == '55555555555' ||
          this.dados.responsavelTelefone == '5555555555' ||
          this.dados.responsavelTelefone == '44444444444' ||
          this.dados.responsavelTelefone == '4444444444' ||
          this.dados.responsavelTelefone == '33333333333' ||
          this.dados.responsavelTelefone == '3333333333' ||
          this.dados.responsavelTelefone == '22222222222' ||
          this.dados.responsavelTelefone == '2222222222' ||
          this.dados.responsavelTelefone == '11111111111' ||
          this.dados.responsavelTelefone == '1111111111'
        )
          return this.msgError('Erro: O campo telefone digitado não é válido.')
        else {
          return this.dados.responsavelTelefone == ''
            ? ''
            : /^[\+]?[(]?[0-9]{2,4}[)]?[-\s\.]?[0-9]{4,5}[-\s\.]?[0-9]{4}$/im.test(this.dados.responsavelTelefone)
            ? true
            : this.msgError('Erro: O campo telefone digitado não é válido.')
        }
      },
      pesquisarOrgao() {
        this.pesquisar = { ...this.pesquisar, currentPage: 1 }
        this.carregarGrid()
      },
      buscarCEP() {
        if (this.dados.responsavelCep.length == 10) {
          useJwt
            .get(`utils/cep/${this.dados.responsavelCep.replace(/\D/g, '')}`)
            .then((response) => {
              this.carregarCidadeEndereco(response.data.uf)
              this.dados = {
                ...this.dados,
                responsavelLogradouro: response.data.logradouro,
                responsavelBairro: response.data.bairro,
                responsavelUf: response.data.uf,
                responsavelCidade: response.data.localidade,
              }
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      carregarCidadeEndereco(item) {
        useJwt
          .get(`utils/cidades/${item}`)
          .then((response) => {
            this.responsavelCidade = response.data
            this.responsavelCidade = response.data
              .flatMap((valor) => valor.nome)
              .reduce((acumulador, atual) => {
                if (!acumulador.includes(atual)) acumulador.push(atual)
                return acumulador
              }, [])
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarGrid() {
        this.isBusy = true
        useJwt
          .pesquisar('cadastro/orgao/GetPesquisar', {
            ...this.pesquisar,
            institutoSelecionado: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.isBusy = false
            this.items = response.data.dados
            this.totalRows = response.data.total

            if (this.items.length == 0) {
              this.$message.error('Não foram encontrados órgãos!')
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarInstituto() {
        if (this.userData.role != 'master') {
          useJwt
            .get(`cadastro/instituto/user/${this.userData.id}`)
            .then((response) => {
              this.instituto = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        } else if (this.userData.role == 'master') {
          useJwt
            .get(`cadastro/instituto`)
            .then((response) => {
              this.instituto = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      novoRegistro() {
        this.dados = { poder: '', esfera: '' }
        this.$refs['modal-cadastro'].show()
      },
      authEExclui(item) {
        this.items.item = item
        this.items.mode = 'authEExclui'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authEExclui') {
            this.excluir(this.items.item)
          }
        }
      },
      excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir o cadastro selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`cadastro/orgao`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erro: Registro possui relacionamentos!',
                      icon: 'BellIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
              })
          }
        })
      },
      editar(item) {
        useJwt
          .edit(`cadastro/orgao`, item)
          .then((response) => {
            this.dados = response.data
            this.carregarCidadeEndereco(this.dados.responsavelUf)
            this.$refs['modal-cadastro'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      salvar(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formOrgao.validate().then((success) => {
          if (success) {
            this.loading = true
            if (this.isEmailValid() == true && this.isTelefoneValid() == true) {
              if (this.dados.id == undefined) {
                useJwt
                  .post(`cadastro/orgao`, this.dados)
                  .then((response) => {
                    this.$swal({
                      title: 'Atenção!',
                      text: 'Cadastrado com sucesso',
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    }).then((result) => {
                      if (result.value) {
                        this.loading = false
                        this.$refs['modal-cadastro'].hide()
                        this.carregarGrid()
                      }
                    })
                  })
                  .catch((error) => {
                    console.error(error)
                    this.loading = false
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: error.response.data.error,
                          icon: 'BellIcon',
                          variant: 'danger',
                        },
                      },
                      {
                        position: 'top-right',
                        timeout: 8000,
                      },
                    )
                  })
              } else {
                const { id, ...aux } = this.dados
                useJwt
                  .update(`cadastro/orgao`, this.dados.id, aux)
                  .then((response) => {
                    this.$swal({
                      title: 'Atenção!',
                      text: 'Registro alterado com sucesso',
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    }).then((result) => {
                      if (result.value) {
                        this.loading = false
                        this.$refs['modal-cadastro'].hide()
                        this.carregarGrid()
                      }
                    })
                  })
                  .catch((error) => {
                    this.loading = false
                    console.error(error)
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: error.response.data.error,
                          icon: 'BellIcon',
                          variant: 'danger',
                        },
                      },
                      {
                        position: 'top-right',
                        timeout: 8000,
                      },
                    )
                  })
              }
            }
          } else {
            this.loading = false
            this.msgError('Erro: Há campos não preenchidos no formulário.')
          }
        })
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
    },
  }
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
